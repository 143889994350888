import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Oops I did it again. V3 of CZaas.com`}</h1>
    <p>{`I wanted to create a way for me to easily manage the contents of my website using my own CMS. During V2 build I thought I nailed it. It was a site where all content was managed using markdown.`}</p>
    <p>{`The caveat was; I needed to check files into source control and deploy them to my digital ocean droplet. None of that was automated and it caused me to never write an article or post new content due to how much of a pain that process was.`}</p>
    <h2>{`The current setup`}</h2>
    <p>{`Using React on the front end and firebase for my authentication, file storage, and database for content. andI deploy my front end using a netlify hook when I merge content into my master branch.`}</p>
    <p>{`I created a simple CMS to create and manage pages, create and manage menu items, upload file assets and display them. It can do pretty much anything a basic CMS can do.`}</p>
    <h3>{`Pages`}</h3>
    <p>{`I can create pages and hide them behind a "isPublished" flag. I can create menus and change the order. I can update the meta title and description. I'm using aa markdown editor that allows me to add HTML so I can align images or any content using my CSS grid.`}</p>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fcms-preview.jpg?alt=media&token=b7e2f6c9-ca57-45cb-9f15-152ad3d9bd2b" width="1024" height="698" alt="Preview of my CMS" />
    <h3>{`Menus`}</h3>
    <p>{`I have two sets of menus:`}</p>
    <ol>
      <li parentName="ol">{`Authenticated`}</li>
      <li parentName="ol">{`Unauthenticated (As of right now, this is just for me)`}</li>
    </ol>
    <p>{`I have drag and drop reordering that saves on drop.`}</p>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fmenu-manager-preview.jpg?alt=media&token=cd6c5e61-5000-4c98-877c-a06fdece83c3" width="1024" height="677" alt="preview of menu manager" />
    <h3>{`Images`}</h3>
    <p>{`I can drag and drop several images at a time to upload images and delete them as needed.`}</p>
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fimage-uploader-preview.jpg?alt=media&token=11d69200-e791-415f-aeb8-87701f9c2615" width="878" height="738" alt="preview of image uploader" />
    <h3>{`Permissions`}</h3>
    <p>{`Firebase has a great way to `}<a href="https://firebase.google.com/docs/database/security/" target="_blank">{`set rules`}</a>{` and compare them to the logged in user. I have restricted anyone who isn't logged in to view anything behind the `}<inlineCode parentName="p">{`isPublished`}</inlineCode>{` flag and dashboard specific data (logged in menu).`}</p>
    <h2>{`Deployment`}</h2>
    <p>{`Originally I was deploying my site using a command line and using firebase website hosting. But it was slow. I looked into other options and started going down a complicated route of deployment and then put it on the shelf for a bit while I completed the CMS.`}</p>
    <p>{`Via a podcast I learned about netlify and how it radicalized the deployment process and hosting simplified. I gave it a shot with an existing project I had on github and it was too simple.`}</p>
    <p>{`I spent no more than 5 minutes pushing this project to github and hooking it up through netlify and white listing the subdomain given to me in firebase to allow read/write permissions and pushed an update to the master branch to watch it immediately kick in and start deploying my updates.`}</p>
    <p>{`I updated my DNS records to point here, deleted my digital ocean droplet and am not looking back.`}</p>
    <h2>{`TODO's`}</h2>
    <p>{`There are many things I still want to do for this site.`}</p>
    <ol>
      <li parentName="ol">{`I want to reduce the CSS footprint, currently importing a normalize lib, grid, code highlighter, and some custom styles.`}</li>
      <li parentName="ol">{`Create a pattern library and finalize the theme. `}<del parentName="li">{`(I have a light/dark theme but opted not to deploy the switching as of right now)`}</del></li>
      <li parentName="ol">{`Explore dropping firebase in favor of using netlify's authentication and serverless lamdas for endpoints and an AWS instance for storing data.`}</li>
      <li parentName="ol">{`Inconsistencies in CMS UX. For pages, they allow drafts and only save to database when you click save. For menus you can drag and drop the order and they save on drop but require you to click save in order to update the text or path.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      